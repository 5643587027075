:root {
    --cs-main: #D89F16;
    --cs-darker-main: #C79723;
    --cs-lighten-main: #FFF6E0;
    --cs-background: #FFFFFF;
    --cs-alt-background: #F7F5F2;
    --cs-lighter-inactive: #DCD9D2;
    --cs-secondary-text: #8E8C89;
    --cs-font: #2B2525;
    --cs-btn-font: #ffffff;
}

.btn-primary {
    --bs-btn-bg: rgba(var(--cs-main), 1);
    --bs-btn-border-color: rgba(var(--cs-main), 1);
    --bs-btn-hover-bg: color-mix(in srgb, rgb(var(--cs-main)) 80%, white);
    --bs-btn-active-bg: color-mix(in srgb, rgb(var(--cs-main)) 80%, black);
    --bs-btn-active-color: rgb(var(--cs-main-text));
    --bs-btn-active-border-color: color-mix(in srgb, rgb(var(--cs-main)) 80%, black);
    --bs-btn-color: rgb(var(--cs-main-text));
    --bs-btn-hover-color: rgb(var(--cs-main-text));
    --bs-btn-disabled-bg: rgba(var(--cs-main), 1);
    --bs-btn-disabled-color: rgb(var(--cs-main-text));
    --bs-btn-disabled-opacity: 40%;
    --bs-btn-disabled-border-color: rgba(var(--cs-main), 1);
}

.btn-outline-primary {
    --bs-btn-color: rgba(var(--cs-main), 1);
    --bs-btn-border-color: rgba(var(--cs-main), 1);
    --bs-btn-active-color: rgb(var(--cs-main-text));
    --bs-btn-active-border-color: rgba(var(--cs-main), 1);
}

[data-bs-theme="light"] {
    --bs-secondary-rgb: 227, 227, 227;
    --bs-secondary-color: #7E7E7E;
    --bs-secondary-text-emphasis: #1D1B20;
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(29, 27, 32, 0.075);
}

[data-bs-theme="dark"] {
    --bs-secondary-rgb: 54, 54, 54;
    --bs-secondary-color: #B9B9B9;
    --bs-secondary-text-emphasis: #E3E3E3;
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}

a {
    color: rgb(var(--cs-main));

    &:hover {
        color: rgba(var(--cs-main), 0.5);
    }
}

.border-primary {
    border-color: rgb(var(--cs-main)) !important;
}

.form-check-input {
    &:checked {
        background-color: rgb(var(--cs-main));
        border-color: rgb(var(--cs-main));
    }

    &:focus {
        border-color: rgba(var(--cs-darker-main), 1);
        box-shadow: 0 0 0 0.25rem rgba(var(--cs-lighten-main), 1);
    }
}

.form-control, .form-select {
    background-color: rgba(var(--bs-secondary-rgb), 1);
    border-color: rgba(var(--cs-lighter-inactive), 1);

    &:focus {
        background-color: rgba(var(--bs-secondary-rgb), 1);
        border-color: rgba(var(--cs-main), 1);
        outline: 0;
        box-shadow: none;
    }
}

.btn-primary, .btn-check:checked + .btn-outline-primary {
    background: rgba(var(--cs-main), 1);

    &:focus {
        background: rgba(var(--cs-main), 1);
    }
}
.text-primary {
    color: rgba(var(--cs-main), 1) !important;
}

.text-primary-lighter {
    color: rgba(var(--cs-main), 0.4) !important;
}

.stroke-primary {
    stroke: rgba(var(--cs-main), 1);
}

a:active {
    .stroke-primary {
        stroke: rgba(var(--cs-darker-main), 1);
    }

    .text-primary {
        color: rgba(var(--cs-darker-main), 1) !important;
    }
}

.fill-primary {
    fill: rgba(var(--cs-main), 1);
}

.fill-primary-darken {
    fill: rgba(var(--cs-darker-main), 1);
}

.stroke-btn-font {
    stroke: rgba(var(--cs-btn-font), 1);
}

.background-btn-font {
    background: rgba(var(--cs-btn-font), 1);
}

.fill-btn-font {
    fill: rgba(var(--cs-btn-font), 1);
}

.fill-font {
    fill: rgba(var(--cs-font), 1);
}

.btn {
    &:focus {
        box-shadow: none !important;
    }
}

img.with-placeholder {
    background: rgba(var(--cs-font), .05);
}

.star-not-selected {
  color: rgb(var(--cs-lighter-inactive));
}

.star-selected>svg>g>path{
  fill: rgb(var(--cs-main));
}

.star-not-selected>svg>g>path {
  fill: rgb(var(--cs-lighter-inactive));
}
