@import "custom-bootstrap";
@import "themes";

//@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&family=Source+Sans+Pro:wght@400;600&display=swap');

html, html body {
    left: 0;
    right: 0;

    // Отключаем чтобы при закрытии модалки скролл происходил мгновенно
    scroll-behavior: auto !important;

    //-webkit-font-smoothing: antialiased;
    //font-family: 'Source Sans Pro', sans-serif;
}

.sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
    box-shadow: 0 -0.25rem 0.5rem rgb(0 0 0 / 8%);
}

.scrolled-bottom {
    .sticky-bottom {
        box-shadow: none;
    }
}

.primary-icon-duotone {
  --fa-primary-color: rgba(var(--cs-main), 1);
  --fa-secondary-color: rgba(var(--cs-main), 0.4);
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


// Скрываем куски интерфейса Fondy
#checkout-container {
    .f-header, .f-info {
        display: none;
    }
}

// Делаем инпут с вводом карточки Stripe побольше
.form-control.StripeElement {
    width: 100%;
    padding: 13px 15px;
}
