$error: #DF1B1B;
$error-light: #FFCDCD;
$warning: #EEA619;
$warning-light: #FFEECC;
$black: #1D1B20;
$dark-grey: #343434; // 54, 54, 54
$grey: #7E7E7E; // 126, 126, 126
$medium-grey: #B9B9B9; // 185, 185, 185
$light-grey: #E3E3E3; // 227, 227, 227
$light: #F4F4F4;
$white: #FFFFFF;

$spacer: 16px;
$spacers: (
        0: 0,
        1: $spacer * 0.25,
        2: $spacer * 0.5,
        3: $spacer * 0.75,
        4: $spacer * 1,
        5: $spacer * 1.25,
        6: $spacer * 1.5,
        7: $spacer * 2,
        8: $spacer * 3,
        9: $spacer * 4,
        10: $spacer * 5,
);

html, html body {
    font-size: 17px;
}

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;

$border-radius-sm: 0.3rem;
$border-radius: 0.6rem;
$border-radius-lg: 0.12rem;

$font-weight-bold: 600;
$headings-font-weight: 600;

$btn-border-radius-lg: 8;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1200px,
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1140px,
);

$enable-negative-margins: true;

$theme-colors: (
    "secondary":  $light-grey,
    "warning":    $warning,
    "danger":     $error,
    "light":      $light,
    "grey":       $grey,
    "dark":       $dark-grey,
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "5x2": calc(2 / 5 * 100%),
);

$body-bg: $white;
$body-bg-subtle: $black;
$body-bg-dark: $black;
$body-tertiary-color: $white;
$body-tertiary-bg: $black;
$body-tertiary-color-dark: $black;
$body-tertiary-bg-dark: $white;


    // Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";


// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
//@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/badge";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";


.lh-0 {
    line-height: 0;
}

.btn-outline-secondary {
    color: $black;
    border-color: $light-grey;
    background-color: transparent;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);


    &:hover {
        color: $black;
        background-color: transparent;
        box-shadow: none;
    }

    &:active {
        color: $light-grey;
        background-color: $light-grey;
        border-color: $light-grey;
    }
}


[data-bs-theme="dark"] {
    .btn-secondary {
        color: $white;
        background-color: $dark-grey;
        border-color: $dark-grey;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

        &:hover {
            color: $white;
            background-color: mix($white, $dark-grey, 20%);
            border-color: mix($white, $dark-grey, 20%);
            box-shadow: none;
        }

        &:active {
            color: $white;
            background-color: mix($black, $dark-grey, 20%);
            border-color: mix($black, $dark-grey, 20%);
        }
    }
    .btn-outline-secondary {
        color: $white;
        background-color: transparent;
        border-color: $dark-grey;

        &:hover {
            color: $white;
        }

        &:active {
            color: $white;
            background-color: $dark-grey;
            border-color: $dark-grey;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
        .border-#{$breakpoint}-left {
            border-left-width: 1px;
            border-left-style: solid;
        }
        .border-#{$breakpoint}-right {
            border-right-width: 1px;
            border-right-style: solid;
        }
        .border-#{$breakpoint}-top {
            border-top-width: 1px;
            border-top-style: solid;
        }
        .border-#{$breakpoint}-bottom {
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }

        .px-#{$breakpoint}-5 {
            padding-left: map-get($spacers, 5);
            padding-right: map-get($spacers, 5);
        }

        .px-#{$breakpoint}-0 {
            padding-left: map-get($spacers, 0);
            padding-right: map-get($spacers, 0);
        }
    }
}

.border-transparent {
    border-color: transparent !important;
}


// TODO: extract parts into separate files

// Utilities
.svh-100 {
    height: 100vh!important; // fallback for older browsers
    height: 100svh!important;
}

.modal-dialog {
  max-width: 860px !important;
}
.modal-content {
  @media (min-width: 576px) {
    // Desktop
    border-radius: 20px;
  }

  @media (max-width: 576px) {
    // Mobile
    border-radius: 8px 8px 0 0;
  }
}
.modal-body {
  padding: 0;
  @media (min-width: 576px) {
    // Desktop
    border-radius: 20px;
  }
  @media (min-width: 576px) {
    // Desktop
    max-height: calc(100vh - 100px);
  }

  @media (max-width: 576px) {
    // Mobile
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.modal-header{
    border-bottom: none;
}